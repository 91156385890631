<template>
</template>
<script>
export default {
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
